import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/security.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Employee Scheduling Software & Time Clock for Security Guards | Camelo"
        metaDescription="Schedule and manage your security staff instantly, capture accurate timesheets, and find replacements quickly with the Camelo scheduling software."
        header="Schedule and manage your security staff instantly."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
